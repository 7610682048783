export const StatusToText = (orderStatus, type = null) => {
  const status = {
    approved: "Gelecek",
    pending: "Son Aramalar",
    active: "Devam Ediyor",
    cancelled: "İptal",
    paymentError: "Ödeme Hatası",
    completed: "Geçmiş",
    earlyReturnRequest: "Erken İade İsteği",
    earlyReturnFinancialConfirmation: "Erken İade Finansal Onay",
    earlyReturnRejected: "Erken İade Reddedildi",
    confirmedEarlyReturn: "Erken İade Onaylandı",
    noShowCancelled: "No Show İptal",
  };

  if (type === "list") {
    // ? convert status lines to array by object
    const statusArray = Object.entries(status).map(([value, text]) => ({
      value,
      text,
    }));
    return statusArray;
  }
  return status[orderStatus];
};

// ? ERP Status

export const ERPStatusToText = (orderStatus, type = null) => {
  const status = {
    pending: "Bekliyor",
    ok: "Aktarıldı",
    error: "Hata",
  };

  if (type === "list") {
    // ? convert status lines to array by object
    const statusArray = Object.entries(status).map(([value, text]) => ({
      value,
      text,
    }));
    return statusArray;
  }
  return status[orderStatus];
};
