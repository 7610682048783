<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
            @click="searchBidCollapsed = !searchBidCollapsed"
          >
            <v-toolbar-title
              >Faturalar
              <font-awesome-icon
                :icon="[
                  'fas',
                  searchBidCollapsed ? 'chevron-up' : 'chevron-down',
                ]"
            /></v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control"
                style="max-width: 250px"
                autocomplete="new"
                placeholder="Hızlı Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
            </div>
          </v-toolbar>

          <CCollapse :show="searchBidCollapsed">
            <CForm @submit.prevent="getInvoices">
              <v-row class="mt-3">
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="searchForm.invoiceNumber"
                    hide-details
                    :label="$t('invoice_number')"
                    type="text"
                  />
                </v-col>
                <v-col md="3">
                  <v-select
                    dense
                    hide-details
                    outlined
                    clearable
                    multiple
                    :items="ERPStatusOptions"
                    item-text="text"
                    item-value="value"
                    v-model="searchForm.erpStatus"
                    label="Fatura Durumu"
                  />
                </v-col>
                <v-col md="3">
                  <v-select
                    dense
                    hide-details
                    outlined
                    clearable
                    multiple
                    :items="reservationStatusOptions"
                    item-text="text"
                    item-value="value"
                    v-model="searchForm.reservationStatus"
                    label="Rezervasyon Durumu"
                  />
                </v-col>

                <v-col md="3">
                  <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="searchForm.invoiceDate"
                        clearable
                        label="Fatura Tarihi"
                        readonly
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="searchForm.invoiceDate = []"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="searchForm.invoiceDate"
                      @change="datePickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    type="submit"
                    block
                    color="primary"
                  >
                    <strong> ARA </strong>
                    <v-icon right dark> mdi-magnify </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </CForm>
          </CCollapse>

          <CCardBody class="p-0" v-if="showDataGrid">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="
                (params) => clickOpenModalButton(`old`, params)
              "
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";
import moment from "moment";

// ? utils
import { StatusToText, ERPStatusToText } from "../../utils/";
Vue.use(VueSweetalert2);

export default {
  name: "Partners",
  components: {
    AgGridVue,
    RoleProvider,
  },
  data() {
    return {
      datePickerMenu: false,
      searchBidCollapsed: true,
      showDataGrid: false,
      searchFilterInput: null,
      searchForm: {
        erpStatus: "",
        invoiceNumber: "",
        invoiceDate: [],
        reservationStatus: [],
      },
      reservationStatusOptions: StatusToText(null, "list"),
      ERPStatusOptions: ERPStatusToText(null, "list"),
      columnDefs: [
        {
          field: "status",
          headerName: "Rezervasyon Durumu",
          enableRowGroup: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          cellRenderer: (params) => {
            var result = "";
            var reservationStatus = params.value;
            if (reservationStatus == "approved") {
              result = this.$t("approved");
            } else if (reservationStatus == "pending") {
              result = this.$t("pending_t");
            } else if (reservationStatus == "active") {
              result = this.$t("active");
            } else if (reservationStatus == "completed") {
              result = this.$t("completed");
            } else if (reservationStatus == "cancelled") {
              result = this.$t("cancelled");
            } else if (reservationStatus == "billed") {
              result = this.$t("billed");
            } else if (reservationStatus == "notbilled") {
              result = this.$t("notbilled");
            } else if (reservationStatus == "canceledbill") {
              result = this.$t("canceledbill");
            } else if (reservationStatus == "earlyReturnRequest") {
              result = this.$t("earlyReturnRequest");
            } else if (
              reservationStatus == "earlyReturnFinancialConfirmation"
            ) {
              result = this.$t("earlyReturnRequestWaitConfirm");
            } else if (reservationStatus == "confirmedEarlyReturn") {
              result = this.$t("confirmEarlReturn");
            } else if (reservationStatus == "paymentError") {
              result = this.$t("paymentError");
            } else if (reservationStatus == "noShowCancelled") {
              result = this.$t("noShowCancelled");
            } else {
              result = this.$t("all");
            }

            return result;
          },
          width: 120,
          hide:
            typeof this.$router.history.current.params.reservationStatus !==
            "undefined"
              ? true
              : false,
        },
        {
          field: "ERP.invoiceTransferStatus",
          cellRenderer: (params) => {
            return params.value == "ok"
              ? this.$t("invoiced")
              : params.value == "pending"
              ? this.$t("pending")
              : params.value == "error"
              ? this.$t("error_t")
              : "";
          },
          headerName: this.$t("invoice_status"),
          width: 140,
        },
        {
          field: "ERP.invoiceDate",
          cellRenderer: (params) => {
            return params.value
              ? moment(params.value).format("DD.MM.YYYY")
              : "";
          },
          headerName: this.$t("invoice_date"),
          width: 140,
        },
        {
          field: "ERP.invoiceNumber",
          headerName: this.$t("invoice_number"),
          width: 140,
        },
        {
          field: "partnerDetail",
          headerName: "Partner",
          width: 120,
          cellRenderer: (params) => {
            return params.value;
          },
          valueGetter: (params) =>
            (params?.data?.partnerDetail?.title || "") +
            (params?.data?.partnerDetail?.brand ? "/" : "") +
            (params?.data?.partnerDetail?.brand || ""),

        },
        {
          field: "utmSource",
          headerName: "Kaynak",
          width: 120,
          valueGetter: (params) => {
            if (typeof params?.data?.utmSource === "object") {
              return params.data.utmSource.utm_source;
            } else if (typeof params?.data?.utmSource === "string") {
              return params.data.utmSource;
            }
          },
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Medium",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_medium || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Campaign",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_campaign || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Term",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_term || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Content",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.utm_content || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "utmSource",
          headerName: "Kaynak-Referans",
          width: 120,
          hide: true,
          valueGetter: (params) => params?.data?.utmSource?.referrer || "",
          cellRenderer: (params) => {
            return params.value;
          },
        },
        {
          field: "reservationType",
          headerName: this.$t("type"),
          cellRenderer: (params) => {
            return this.$t(params.value);
          },
          width: 100,
        },

        {
          field: "searchPrice.vendor.name",
          headerName: this.$t("vendor"),
          enableRowGroup: true,
          width: 100,
        },
        {
          field: "guest",
          enableRowGroup: true,
          headerName: this.$t("user_type"),
          hide: true,
          width: 120,
          cellRenderer: (params) => {
            switch (params.value) {
              case true:
                return this.$t("quest_member");
              case false:
                return this.$t("registered_member");
              default:
                return this.$t("unknown");
            }
          },
        },

        {
          field: "driverInfo.name",
          headerName: this.$t("name"),
          width: 110,
        },
        {
          field: "driverInfo.lastname",
          headerName: this.$t("lastname"),
          width: 110,
        },

        {
          field: "driverInfo.identity",
          headerName: "TCKN / Pasaport",
          cellRenderer: (params) => {
            const value = params?.value;
            if (value.certificateType === "tc" && value.value)
              return `<b>TC:</b> ${value.value}`;
            else if (value.certificateType === "passport" && value.value)
              return `<b>P:</b> ${value.value}`;
            else return "Girilmemiş";
          },
        },

        {
          field: "reservationCode",
          headerName: this.$t("reservation_number"),
          width: 130,
        },
        {
          field: "reservationCode",
          valueGetter: (params) => {
            return `https://www.renticar.com/gosterge-paneli/rezervasyonlarim/detay/${params?.data?.reservationCode}/${params?.data?.driverInfo.email}#comment`;
          },
          hide: true,
          headerName: this.$t("survey_link"),
          width: 130,
        },
        {
          field: "vendorReservationCode",
          headerName: this.$t("vendor_reservation_number"),
          width: 140,
        },
        {
          field: "rentalDayRange",
          headerName: this.$t("rental_day_range"),
          width: 90,
        },
        {
          field: "driverInfo.flightNo",
          headerName: this.$t("flight_number"),
          width: 110,
          hide: true,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("DD.MM.YYYY");
          },
          valueGetter: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("YYYY-MM-DD");
          },
          headerName: this.$t("reservation_date"),
          width: 100,
          filter: "agDateColumnFilter",
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("HH:mm");
          },
          valueGetter: (params) => {
            return (
              moment(params.data.reservationDateTime).local().format("HH:mm") ||
              ""
            );
          },
          headerName: this.$t("reservation_hour"),
          width: 90,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("DD.MM.YYYY");
              }
            }
          },
          headerName: this.$t("cancel_date"),
          width: 140,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("HH:mm");
              }
            }
          },
          headerName: this.$t("cancel_time"),
          width: 140,
        },
        {
          field: "refund.place",
          headerName: this.$t("cancel_author"),
          width: 100,
        },
        {
          enableRowGroup: true,
          field: "refund.message",
          headerName: this.$t("reason_for_cancellation"),
          width: 150,
        },
        {
          enableRowGroup: true,
          field: "status",
          headerName: this.$t("error_reason"),
          width: 120,
          cellRenderer: (data) =>
            data.value == "paymentError"
              ? data.data.bankTransactions[
                  data.data.bankTransactions.length - 1
                ]?.message
              : "",
        },

        {
          field: "prices.dailyRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.dailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("daily_rental_fee"),
          width: 110,
        },
        {
          field: "prices.retailDailyRentalPrice",
          hide: true,
          valueGetter: (params) => {
            return params.data?.prices?.retailDailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("retailDailyRentalPrice"),
          width: 110,
        },
        {
          field: "rentalCurrencyPrice.currencyCode",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            return currencyCode;
          },
          type: "rightAligned",
          headerName: this.$t("reservation_currency"),
          width: 170,
        },
        {
          field: "rentalCurrencyPrice.vendorDaily",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const vendorDaily = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.vendorDaily}${currencyCode}`
              : `${params.data.prices.retailDailyRentalPrice[currencyCode]}${currencyCode}`;
            return vendorDaily;
          },
          type: "rightAligned",
          headerName: this.$t("supplier_daily_currency_amount"),
          width: 170,
        },

        {
          field: "rentalCurrencyPrice.vendorTotal",
          type: "rightAligned",
          width: 250,
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const vendorTotal = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.vendorTotal}${currencyCode}`
              : `${params.data.prices.retailTotalRentalPrice[currencyCode]}${currencyCode}`;
            return vendorTotal;
          },
          headerName: this.$t("supplier_total_currency_amount"),
          aggFunc: "sum",
        },
        {
          field: "rentalCurrencyPrice.totalDaily",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const totalDaily = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.totalDaily}${currencyCode}`
              : `${params.data.prices.dailyRentalPrice[currencyCode]}${currencyCode}`;
            return totalDaily;
          },
          type: "rightAligned",
          headerName: this.$t("customer_daily_currency_amount"),
          width: 170,
        },
        {
          field: "rentalCurrencyPrice.total",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            if (currencyCode === "TRY") return "";
            const total = this.checkRentalCurrency(params)
              ? `${params.data.rentalCurrencyPrice.total}${currencyCode}`
              : `${params.data.prices.totalRentalPrice[currencyCode]}${currencyCode}`;
            return total;
          },
          type: "rightAligned",
          headerName: this.$t("customer_total_currency_amount"),
          width: 170,
        },
        {
          field: "currency.forexSelling",
          cellRenderer: (params) => {
            let forexSelling =
              params.data.currency.forexSelling == 1
                ? ""
                : params.data.currency.forexSelling.toLocaleString("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                  });
            return forexSelling;
          },
          type: "rightAligned",
          headerName: "İşlem Kuru",
          width: 100,
        },
        {
          field: "searchPrice.totalDays",
          cellRenderer: (params) => {
            return params.value;
          },
          type: "rightAligned",
          headerName: this.$t("rental_days"),
          width: 70,
        },
        {
          field: "prices.totalRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.totalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_payment_price"),
          width: 110,
        },
        {
          field: "prices.retailTotalRentalPrice",
          hide: true,
          valueGetter: (params) => {
            return params?.data?.prices?.retailTotalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("retailTotalRentalPrice"),
          width: 110,
        },
        {
          field: "selectedExtras",
          valueGetter: (params) => {
            return (
              params.data?.prices?.totalExtraPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_extras"),
          width: 110,
        },

        {
          hide: true,
          field: "bankTransactions",
          headerName: this.$t("installment_count"),
          width: 110,
          cellRenderer: (params) => {
            return (
              params.value.find((item) => item.type == "paymentSuccess")?.data
                ?.InstallmentCount || ""
            );
          },
        },
        {
          field: "searchPrice.pricing.vendorDailyPrice",
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorDailyPrice !=
              "object"
            )
              return 0;
            return (
              params.data?.searchPrice?.pricing?.vendorDailyPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: "API " + this.$t("daily_price"),
          width: 140,
        },
        {
          field: "searchPrice.pricing.vendorTotalPrice",
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorTotalPrice !=
              "object"
            )
              return 0;
            return (
              params.data?.searchPrice?.pricing?.vendorTotalPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("vendor_total_rental_price"),
          width: 140,
        },
        {
          field: "posModule",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Pos",
          width: 140,
          hide: true,
        },
        {
          field: "metropolCard",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Metropol Kart",
          width: 140,
          hide: true,
        },
        {
          field: "orderId",
          headerName: this.$t("order_number"),
          width: 300,
          hide: true,
        },
        {
          field: "payments",
          type: "rightAligned",
          cellStyle: { color: "white", "background-color": "green" },
          hide: true,
          valueGetter: (params) => {
            if (params?.data.payments?.length > 0) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "payment"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                deger.forEach((sayi) => {
                  sonuc += sonuc + sayi.totalAmount;
                });
                return Number(Func.toMoneyFormat(sonuc));
              }
            } else {
              return 0;
            }
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: "TRY",
            });
          },
          headerName: this.$t("amount_paid"),
          width: 140,
        },
        {
          field: "payments",
          hide: true,
          type: "rightAligned",
          valueGetter: (params) => {
            if (params?.data?.payments?.length > 0) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "payment"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                var result = 0;
                deger.forEach((sayi) => {
                  sonuc += sonuc + sayi.totalAmount;
                });
                if (params.data?.prices?.totalPrice?.TRY) {
                  result = sonuc - params.data?.prices?.totalPrice.TRY;
                  return Number(Func.toMoneyFormat(sonuc)).toLocaleString(
                    "tr-TR",
                    {
                      style: "currency",
                      currency: "TRY",
                    }
                  );
                }
              }
            }
            return 0;
          },
          headerName: this.$t("interest"),
          width: 140,
        },
        {
          field: "payments",
          type: "rightAligned",
          cellStyle: { color: "white", "background-color": "red" },
          valueGetter: (params) => {
            if (
              typeof params.data.searchPrice?.pricing?.vendorTotalPrice?.TRY !==
              "undefined"
            ) {
              var deger = params.data?.payments?.filter(
                (payment) => payment.paymentType === "refund"
              );
              if (typeof deger === "undefined") {
                return 0;
              } else {
                var sonuc = 0;
                deger.forEach((sayi) => {
                  sonuc = sonuc + sayi.totalAmount;
                });
                return sonuc.toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                });
              }
            }
            return 0;
          },
          headerName: this.$t("returned"),
          hide: true,
          width: 140,
        },
        {
          field: "carDeliveryReturn.delivery.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("purchase_date"),
          width: 140,
          filter: "agDateColumnFilter",
        },
        {
          field: "carDeliveryReturn.return.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("returned_date"),
          width: 140,
          filter: "agDateColumnFilter",
        },

       
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData",
          valueGetter: (params) => {
            return params.data.searchPrice.campaignData != null
              ? this.$t("yes")
              : this.$t("not");
          },
          headerName: this.$t("with_a_campaign"),
          width: 140,
          hide: true,
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData.info.name.tr",
          headerName: this.$t("campaign_name"),
          valueGetter: (params) => {
            const data = params.data.searchPrice;
            if (data?.campaignData?.info?.name?.tr) {
              return data.campaignData.info.name.tr;
            } else if (data?.campaignData?.info?.name) {
              return data.campaignData.info.name;
            } else {
              return "Bilinmiyor";
            }
          },
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountRate",
          headerName: this.$t("campaign_discount_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountAmount",
          headerName: this.$t("campaign_discount_price"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.vendorCommission",
          headerName: this.$t("campaign_vendor_commision_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("delivery_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.return.name",
          headerName: this.$t("return_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("differen_returns"),
          width: 110,
          valueGetter: (params) => {
            return params.data.carDeliveryReturn.return.name !=
              params.data.carDeliveryReturn.delivery.name
              ? this.$t("yes")
              : this.$t("not");
          },
          hide: true,
        },
        {
          field: "prices.dropPrice",
          headerName: this.$t("different_return_amount"),
          type: "rightAligned",
          width: 140,
          valueGetter: (params) => {
            return (
              params.data?.prices?.dropPrice[this.$store.state.currency] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params?.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
        },
        {
          field: "searchPrice.car.model",
          headerName: "Model",
          width: 100,
        },
        {
          field: "searchPrice.car.brand",
          headerName: this.$t("brand"),
          width: 100,
        },

        {
          field: "driverInfo.email",
          headerName: this.$t("driver_email"),
          width: 200,
        },
        {
          field: "driverInfo.phone",
          headerName: this.$t("driver_phone"),
          width: 130,
          hide: true,
        },
        {
          field: "paymentType",
          valueGetter: (params) => {
            let type = params?.data?.paymentType
              ? this.$store.state.ceviri[params.data.paymentType]
              : "";
            return type;
          },
          headerName: this.$t("payment_type"),
          width: 110,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
            text: "asdas",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      rowSelection: "multiple",
      rowData: [],
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.searchForm.invoiceDate
        ? this.$moment(this.searchForm.invoiceDate).format("DD/MM/YYYY")
        : "";
    },
  },
  created() {},
  methods: {
    async getInvoices() {
      this.$Progress.start();
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API_URL + "admin/invoices",
          this.searchForm
        );
        this.rowData = data;
      } catch (error) {
        this.alert("Bir sorun oluştu");
      } finally {
        this.$Progress.finish();
        this.searchBidCollapsed = false;
        this.showDataGrid = true;
      }
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>
